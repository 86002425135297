import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  setupAxios,
  // setupFirebase,
  setupGlobal,
  setupI18n,
  setupLog,
} from './setup';

import 'moment/locale/zh-tw';
import 'antd/dist/antd.variable.min.css';
import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const App = lazy(() => import('./App'));

(async () => {
  moment.locale('zh-tw');

  setupGlobal();
  setupLog();
  // setupFirebase();
  setupAxios();
  await setupI18n();

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Suspense fallback={null}>
      <App />
    </Suspense>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
